export default [
  {
    _name: "CSidebarNav",
    _children: [

      {
        id: 1,
        _name: "CSidebarNavItem",
        name: "Sale Order",
        to: "/sale-order",
        icon: "cil-list",
        key: "sale-order",
      },
      {
        id: 2,
        _name: "CSidebarNavItem",
        name: "Upload",
        to: "/upload",
        icon: "cil-cloud-upload",
        key: "upload",
      },
      // {
      //   id: 3,
      //   _name: "CSidebarNavItem",
      //   name: "Create Sale Order",
      //   to: "/create-sale-order",
      //   icon: "cil-plus",
      //   key: "create-sale-order",
      // },
      // {
      //   id: 19,
      //   _name: "CSidebarNavDropdown",
      //   name: "Marketing Automation",
      //   icon: "cil-loop-circular",
      //   key: "marketing_automation",

      //   _children: [
      //     {
      //       id: 20,
      //       _name: "CSidebarNavItem",
      //       name: "Automation",
      //       to: "/automation",
      //       key: "automation",
      //     },
      //     {
      //       id: 21,
      //       _name: "CSidebarNavItem",
      //       name: "Send Message",
      //       to: "/automation/sendmessage",
      //       key: "send_message",
      //     },
      //   ],
      // },
    ],
  },
];
